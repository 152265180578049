import { axiosAuthorized } from "../../api/config"

export const getRefferals = async (userId) => {
    try {
        const response = await axiosAuthorized.get(`/hospitalrefferal/${userId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateRefferalStatus = async (refferalId, refferal_status) => {
    try {
        const url = `/hospitalrefferal/update_hospitalrefferal/${refferalId}`;
        const response = await axiosAuthorized.put(url, { refferal_status });
        return response;
    } catch (error) {
        throw error;
    }
}

export const addRefferal = async (initialState, hospital_lab_report, hospital_insurance_details, hospital_prescription, refferalId = null) => {
    try {
        const requestData = {
            ...initialState,
        }
        const url = refferalId ? `/hospitalrefferal/update_hospitalrefferal/${refferalId}` : `/hospitalrefferal/add_hospitalrefferal`;
        const response = await axiosAuthorized[refferalId ? 'put' : 'post'](url, requestData);
        return response;
    } catch (error) {
        throw error;
    }
}