import React from "react";
import { useMemo } from "react";
import Table from "../Common/Table/Table";
import { updateRefferalStatus } from "../../utils/referUtils/referUtils";

const ReferTable = ({ datas, setEditData, setOpen, setRefersFn }) => {
  const headings = useMemo(
    () => [
      "P-Name",
      "P-ID",
      "Contact",
      "Region",
      "Doctor Cost",
      "Medidocs Qoutation",
      "R-Status",
      "P-Details",
      "N-Status",
    ],
    []
    );
  const updateStatus = (refferalId, status) => {
       const confirmed = window.confirm("Are you sure you want to do this action ?");
       if (!confirmed) return;
      updateRefferalStatus(refferalId, status).then(res => setRefersFn()).catch(err => console.error(err));
  }
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.patient_name}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.patient_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.contact_no}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.region}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.doctor_approx_cost}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.medidocs_quotation}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            {data?.refferal_status === "Pending" ? (
              <div className="text-sm text-white flex gap-2 capitalize">
                <button
                  onClick={() => {
                    updateStatus(data?._id, "Rejected");
                  }}
                  className="px-2 py-1 text-center bg-red-500 rounded-full"
                >
                  Reject
                </button>
                <button
                  onClick={() => {
                    updateStatus(data?._id, "Accepted");
                  }}
                  className="px-2 py-1 text-center bg-green-500 rounded-full"
                >
                  Accept
                </button>
              </div>
            ) : (
              <div
                className={`text-sm text-center capitalize px-2 py-1 rounded-full text-white ${
                  data?.refferal_status === "Rejected"
                    ? "bg-red-500"
                    : "bg-green-500"
                }`}
              >
                {data?.refferal_status}
              </div>
            )}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-white text-center bg-primary rounded-md capitalize p-1 cursor-pointer "
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              Patient Details
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.negotiation_status}
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default ReferTable;
