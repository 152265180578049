import React, { useCallback, useEffect, useState } from "react";
import Consultation from "./Consultaion/Consultation";
import { useLocation } from "react-router-dom";
// import Lab from './Lab/Lab';
// import AppointModal from './AppointModal';
import { getConsultatoinAppointments } from "../../utils/bookConsultation/ConsultationUtils";
// import { getLabAppointments } from '../../utils/bookLab/labUtils';
// import FollowUpModal from '../FollowUpAppointmentComponents/FollowUpModal';
// import FollowUpLabModal from '../FollowUpAppointmentComponents/FollowUpLabModal';
import RegisterModal from "./RegisterModal";
import AppointmentModal from "./AppointmentModal";
import { useAuth } from "../../context/useAuth";

const AppointmentsMainSection = () => {
  const user = useAuth().authState.user;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const selectedValue = queryParams.get("selected");

  const patient_id = queryParams.get("patient_id");
  const email = queryParams.get("email");
  const phone_number = queryParams.get("phone_number");

  // const [selected, setSelected] = useState(1);
  const [open, setOpen] = useState(false);
  const [consultationAppointments, setConsultaionAppointments] = useState([]);
  const [isRevisit, setIsRevisit] = useState(false);
  const [isFollowup, setIsFollowup] = useState(false);
  const [patientDetails, setPatientDetails] = useState(null);
  const [mode, setMode] = useState("");
  // const [labAppointments, setLabAppointments] = useState([]);
  // const setLabAppointmentsFn = useCallback(() => {
  //   getLabAppointments()
  //     .then(res => { setLabAppointments(res.data.data) })
  //     .catch(err => console.log(err));
  // }, [])
  const setconsultationAppointmentsFn = useCallback(() => {
    getConsultatoinAppointments(user?._id)
      .then((res) => {
        setConsultaionAppointments(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [user?._id]);
  useEffect(() => {
    setconsultationAppointmentsFn();
    // setLabAppointmentsFn();
  }, [
    setconsultationAppointmentsFn,
    // setLabAppointmentsFn
  ]);
  const closeFollowAndRevisit = (value) => {
    setIsRevisit(value);
    setIsFollowup(value);
    setPatientDetails(null);
    setMode("");
  }
  return (
    <>
      {open && (
        <RegisterModal
          setOpen={setOpen}
          setDatasFn={setconsultationAppointmentsFn}
        />
      )}
      {
        patientDetails &&
        (
        <AppointmentModal
          appointmentDetails={patientDetails}
          is_follow_up={isFollowup}
          is_re_visit={isRevisit}
          setOpen={closeFollowAndRevisit}
          setDatasFn={setconsultationAppointmentsFn}
          mode={mode}
        />
      )}
      <div className="flex flex-col w-full h-full">
        <div className="flex w-full justify-end items-center gap-3">
          {/* <div className="flex gap-3">
            <button
              onClick={() => setSelected(1)}
              className={`flex px-3 border border-primary py-1 rounded-md capitalize ${
                selected === 1
                  ? "bg-primary text-white"
                  : "bg-white text-primary"
              }`}
            >
              consultancy
            </button>
            <button
              onClick={() => setSelected(2)}
              className={`flex px-3 border border-primary py-1 rounded-md capitalize ${
                selected === 2
                  ? "bg-primary text-white"
                  : "bg-white text-primary"
              }`}
            >
              lab
            </button>
          </div> */}
          <div className="flex">
            <button
              onClick={() => setOpen(true)}
              className={`flex px-3 border border-primary py-1 rounded-md bg-primary text-white`}
            >
              Appointment
            </button>
          </div>
        </div>
        {
          // selected === 1 &&
          (
          <Consultation
            appointments={consultationAppointments}
            setAppointmentsFn={setconsultationAppointmentsFn}
            setIsRevisit={setIsRevisit}
            setIsFollowup={setIsFollowup}
            setPatientDetails={setPatientDetails}
            setMode={setMode}
            searchParams={{ patient_id, email, phone_number }}
          />
          )
        }
        {/* {selected === 2 && (
          <Lab
            appointments={labAppointments}
            setAppointmentsFn={setLabAppointmentsFn}
            setIsRevisit={setIsRevisit}
            setIsFollowup={setIsFollowup}
            setPatientDetails={setPatientDetails}
            setMode={setMode}
            searchParams={{ patient_id, email, phone_number }}
          />
        )} */}
      </div>
    </>
  );
};

export default AppointmentsMainSection;
