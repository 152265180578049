export const validateLoginForm = (initialState, setErr) => {

    const idPattern = /^MD-\d*$/;
    const passwordPattern = /^.{8,}$/
    
  if (!idPattern.test(initialState.hospital_id.trim())) {
        setErr({ global: "Invalid Hospital Id" });
        return;
    }
    if (!passwordPattern.test(initialState.password)) {
        setErr({ global: "Invalid Password" });
        return;
    }
    return true;
}