import React from "react";
import { AboutUsMainSection } from "../../components/AboutUsComponents";
const AboutUs = () => {
  return (
    <div className=" w-full h-full px-2 py-8 flex flex-col gap-4">
      <AboutUsMainSection/>
    </div>
  );
};

export default AboutUs;
