import React from 'react'

const RequestMode = ({selected,setSelected}) => {
  return (
        <div className="flex font-semibold gap-3 text-non_selected cursor-pointer">
          <span className={`${selected === "Approved" && "text-primary"}`}  onClick={()=>{setSelected("Approved");}}>Approved</span>
          <span className="text-black">|</span> 
          <span className={`${selected === "Requested" && "text-primary"  }`}  onClick={()=>{setSelected("Requested");}}>Requested</span>
        </div>
  )
}

export default RequestMode