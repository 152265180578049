import { Layout } from './layout';
import { Route, Routes } from 'react-router-dom';
import { AboutUs, Appointments, Doctors, FollowsAppointments, Login, Refer } from './pages';
import { AuthProtected, UserProtected } from './components/ProtectedRoutes/UserProtected';

function App() {
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
        <Route index={true} element={<Appointments />} />
        {/* <Route path="/followup/appointments" element={<FollowsAppointments />} /> */}
        <Route path="/doctors" element={<Doctors/>} />
        <Route path="/about" element={<AboutUs/>} />
        <Route path="/refer" element={<Refer/>} />
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;
