import React from "react";
import ReferMainSection from "../../components/ReferComponents/ReferMainSection";

const Refer = () => {
  return (
    <div className="w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4">
      <ReferMainSection />
    </div>
  );
};

export default Refer;
